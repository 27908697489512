import { useEffect, useState } from "react";
import {
  PaginatedResponse,
  Plugin,
  PluginWebhook,
  fetchPlugin,
  fetchPluginWebhooks,
} from "src/shared/api";
import LoadingSpinner from "src/shared/components/LoadingSpinner";
import PluginsPageLayout from "src/shared/components/PluginsPageLayout";
import { useParams } from "react-router-dom";
import { Button } from "src/shared/components/Button";
import { PluginInstallationContainer } from "./index.styles";
import NewPluginWebhookModal from "./NewPluginWebhookModal";
import { useModalContext } from "src/shared/context/ModalProvider";
import EditPluginInstallationModal from "./EditPluginWebhookModal";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { checkIsPluginAdmin } from "src/shared/aws";

function PluginWebhooks() {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [plugin, setPlugin] = useState<Plugin | null>(null);
  const [pluginWebhooks, setPluginWebhooks] =
    useState<PaginatedResponse<PluginWebhook> | null>(null);
  const { openModal } = useModalContext();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isPluginAdmin, setIsPluginAdmin] = useState(false);

  useEffect(() => {
    const fetchApiRequest = async () => {
      const pluginWebhooks = await fetchPluginWebhooks(id);
      const plugin = await fetchPlugin(id);
      setPluginWebhooks(pluginWebhooks);
      setPlugin(plugin);
      setLoading(false);
    };
    fetchApiRequest();
  }, [id, shouldRefetch]);

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin());
    };
    getIsPluginAdmin();
  }, []);

  const loadMore = async () => {
    const lastId = pluginWebhooks?.data[pluginWebhooks?.data.length - 1].id;
    setLoading(true);
    const result = await fetchPluginWebhooks(id, lastId);
    setPluginWebhooks({
      data: [...(pluginWebhooks?.data ?? []), ...result.data],
      totalCount: result.totalCount,
      hasMore: result.hasMore,
    });
    setLoading(false);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: pluginWebhooks?.hasMore ?? false,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  const refetch = () => {
    setShouldRefetch(!shouldRefetch);
  };

  if (
    (loading && pluginWebhooks?.data.length === 0) ||
    (loading && !pluginWebhooks)
  ) {
    return <LoadingSpinner />;
  }

  if (!plugin || !pluginWebhooks?.data) {
    return null;
  }

  const webhooks = pluginWebhooks?.data;

  const makeActions = (plugin: Plugin) => {
    if (!isPluginAdmin) {
      return [];
    }
    return [
      <Button
        onClick={() =>
          openModal(<NewPluginWebhookModal plugin={plugin} refetch={refetch} />)
        }
      >
        New webhook
      </Button>,
    ];
  };

  return (
    <PluginsPageLayout
      ref={rootRef}
      plugin={plugin}
      backTo="/plugins"
      actions={makeActions(plugin)}
    >
      {webhooks.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1.5rem",
            }}
          >
            <div
              style={{
                paddingLeft: 0,
                padding: "0.5rem",
                fontWeight: "bold",
                flex: 3,
              }}
            >
              Webhooks
            </div>
            <div
              style={{
                paddingLeft: 0,
                padding: "0.5rem",
                fontWeight: "bold",
                flex: 1,
              }}
            >
              Secret
            </div>
          </div>
          {webhooks.map((webhook) => (
            <PluginInstallationContainer
              onClick={() =>
                openModal(
                  <EditPluginInstallationModal
                    plugin={plugin}
                    pluginWebhook={webhook}
                    refetch={refetch}
                  />
                )
              }
            >
              <div style={{ paddingLeft: 0, padding: "0.5rem", flex: 3 }}>
                {webhook.uri}
              </div>
              <div style={{ paddingLeft: 0, padding: "0.5rem", flex: 1 }}>
                wk_secret_*************
              </div>
            </PluginInstallationContainer>
          ))}
        </>
      ) : (
        <div style={{ marginTop: "1.5rem" }}>No plugin webhooks found.</div>
      )}
      {(loading || pluginWebhooks?.hasMore) && (
        <div ref={sentryRef}>
          <LoadingSpinner />
        </div>
      )}
    </PluginsPageLayout>
  );
}

export default PluginWebhooks;
